import React, { useState } from 'react';
import {
  AppBar, Toolbar, Typography, Tabs, Tab, Box, Card, CardContent,
  CardActions, Button, LinearProgress, Radio, RadioGroup,
  FormControlLabel, FormControl, FormLabel, Switch,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const questions = [
  { id: 1, text: "お子様は自分の名前を言えますか？" },
  { id: 2, text: "お子様は簡単な指示に従えますか？" },
  { id: 3, text: "お子様は他の子供と遊ぶことを楽しみますか？" },
  // さらに質問を追加...
];

const DevelopmentApp: React.FC = () => {
  const [value, setValue] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [date, setDate] = useState<Date | null>(new Date());
  const [isAdmin, setIsAdmin] = useState(false);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(prev => Math.min(prev + 1, questions.length - 1));
  };

  const handlePrevQuestion = () => {
    setCurrentQuestion(prev => Math.max(prev - 1, 0));
  };

  const mockData = [
    { name: '言語', 現在: 4, 過去: 3 },
    { name: '認知', 現在: 3, 過去: 2 },
    { name: '運動', 現在: 5, 過去: 4 },
    { name: '社会性', 現在: 2, 過去: 2 },
    { name: '感覚', 現在: 4, 過去: 3 },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            発達検査アプリ
          </Typography>
          <Switch
            checked={isAdmin}
            onChange={() => setIsAdmin(!isAdmin)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant="body1">
            {isAdmin ? '管理者モード' : 'ユーザーモード'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="発達検査" />
        <Tab label="結果分析" />
        <Tab label="アドバイス" />
        <Tab label="プロフィール" />
        {isAdmin && <Tab label="管理者" />}
      </Tabs>
      <Box sx={{ p: 3 }}>
        {value === 0 && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                発達検査
              </Typography>
              <LinearProgress variant="determinate" value={(currentQuestion + 1) / questions.length * 100} sx={{ mb: 2 }} />
              <Typography variant="body1" gutterBottom>
                質問 {currentQuestion + 1}/{questions.length}: {questions[currentQuestion].text}
              </Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">回答</FormLabel>
                <RadioGroup row>
                  <FormControlLabel value="yes" control={<Radio />} label="はい" />
                  <FormControlLabel value="no" control={<Radio />} label="いいえ" />
                  <FormControlLabel value="sometimes" control={<Radio />} label="時々" />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={handlePrevQuestion} disabled={currentQuestion === 0}>前の質問</Button>
              <Button size="small" onClick={handleNextQuestion} disabled={currentQuestion === questions.length - 1}>次の質問</Button>
            </CardActions>
          </Card>
        )}
        {value === 1 && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                検査結果
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={mockData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="現在" fill="#8884d8" />
                  <Bar dataKey="過去" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}
        {value === 2 && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                アドバイスと推奨
              </Typography>
              <Typography variant="h6" gutterBottom>推奨トレーニング</Typography>
              <ul>
                <li>言語能力向上エクササイズ</li>
                <li>社会性スキルワークショップ</li>
              </ul>
              <Typography variant="h6" gutterBottom>栄養アドバイス</Typography>
              <Button variant="outlined" sx={{ mr: 1, mb: 1 }}>オメガ3サプリメント</Button>
              <Button variant="outlined" sx={{ mr: 1, mb: 1 }}>ビタミンD</Button>
              <Typography variant="h6" gutterBottom>関連動画</Typography>
              <Button variant="contained" fullWidth sx={{ mb: 2 }}>言語発達を促す遊び方</Button>
              <Typography variant="h6" gutterBottom>カウンセリング予約</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="日付を選択"
                  value={date}
                  onChange={(newValue: Date | null) => setDate(newValue)}
                />
              </LocalizationProvider>
            </CardContent>
            <CardActions>
              <Button size="small" variant="contained" fullWidth>予約を確定する</Button>
            </CardActions>
          </Card>
        )}
        {value === 3 && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                プロフィールと決済情報
              </Typography>
              <Typography variant="h6" gutterBottom>個人情報</Typography>
              <Typography variant="body1">名前: 山田 花子</Typography>
              <Typography variant="body1">年齢: 5歳</Typography>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>決済情報</Typography>
              <Button variant="contained" fullWidth sx={{ mb: 2 }}>クレジットカード情報を更新</Button>
              <Typography variant="h6" gutterBottom>定期購入</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="body1">月額プラン</Typography>
                <Button variant="outlined" size="small">変更</Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1">次回請求日: 2024/7/1</Typography>
                <Button variant="outlined" color="error" size="small">解約</Button>
              </Box>
            </CardContent>
          </Card>
        )}
        {isAdmin && value === 4 && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                管理者ダッシュボード
              </Typography>
              <Typography variant="h6" gutterBottom>ユーザー統計</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>統計項目</TableCell>
                      <TableCell align="right">数値</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">総ユーザー数</TableCell>
                      <TableCell align="right">1,234</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">アクティブユーザー</TableCell>
                      <TableCell align="right">789</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>レポート作成</Typography>
              <Button variant="contained" fullWidth sx={{ mb: 2 }}>障害福祉用書式でプリント</Button>
              <Typography variant="h6" gutterBottom>メールマガジン</Typography>
              <Button variant="contained" fullWidth>新規メールマガジンを作成</Button>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default DevelopmentApp;
